import { Button } from "@/components/Shared/Button/Button";
import { Container } from "@/components/Shared/Container/Container";
import { Overlay } from "@/components/Shared/Overlay/Overlay";
import { SectionHeader } from "@/components/Shared/SectionHeader/SectionHeader";
import { Box, BoxProps, Flex, Image } from "@chakra-ui/react";
import { Link } from "gatsby";
import React, { ReactNode } from "react";

export interface HeroProps extends BoxProps {
  title: string;
  imageUrl: string;
  description: string | ReactNode | ReactNode[];
  cta?: {
    text: string;
    href: string;
  };
}

export const Hero = (props: HeroProps) => {
  const { title, description, imageUrl, cta, ...restOfProps } = props;
  return (
    <Box
      height={{
        base: 310,
        sm: 310,
        md: 310,
        xl: 310
      }}
      bg="secondary.500"
      position="relative"
    >
      {title && <Overlay opacity={0} />}
      <Box position="absolute" h="full" w="full">
        <Image
          src={imageUrl}
          alt={title}
          w="full"
          height="full"
          objectFit="cover"
        />
      </Box>
      <Box position="absolute" h="full" w="full" zIndex={2}>
        <Container h="full" maxW="container.lg">
          <Flex
            py={10}
            as="article"
            flexDir="column"
            h="full"
            justify="center"
            textAlign="center"
            align="center"
            gridGap={6}
          >
            <SectionHeader color="white" title={title} subtitle={description} />
            {cta && (
              <Link to={cta.href}>
                <Button size="lg">{cta.text}</Button>
              </Link>
            )}
          </Flex>
        </Container>
      </Box>
    </Box>
  );
};
