import { Box, BoxProps } from "@chakra-ui/react";
import React from "react";

export interface OverlayProps extends BoxProps {}

export const Overlay = (props: OverlayProps) => {
  const { opacity = 40, zIndex = 1, ...restOfProps } = props;
  return (
    <Box
      bg={"black"}
      zIndex={zIndex}
      opacity={`${opacity}%`}
      h="full"
      w="full"
      position="absolute"
      {...restOfProps}
    />
  );
};
